import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
import beautyRequst from '@/utils/beautyRequest';
import { getValidToken } from '@/utils/storage';
export default {
  // 用到了
  queryTemplate: function queryTemplate() {
    return request.get('/app/v1/common/dictTree/chkPointVersion/0').then(function (res) {
      return res;
    });
  },
  // 用到了
  queryCitylist: function queryCitylist() {
    return request.get('/app/v1/project-list/citylist').then(function (res) {
      return res;
    });
  },
  // 用到了
  addDicMessage: function addDicMessage(params) {
    return request.post('/app/v1/chk-point/add-version', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  deleteTempClass: function deleteTempClass(params) {
    return request.post('/app/v1/chk-point/del-version', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  updateTempClass: function updateTempClass(params) {
    return request.post('/app/v1/chk-point/upd-version', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  queryRightTemplate: function queryRightTemplate(id) {
    return request.get("/app/v1/common/dictTree/".concat(id, "/0")).then(function (res) {
      return res;
    });
  },
  // 用到了
  rightUpdate: function rightUpdate(params) {
    return request.post('/app/v1/chk-point/upd-point', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  rightAddDic: function rightAddDic(params) {
    return request.post('/app/v1/SpaceDesignStandard/insertByRoomID', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  rightDeleteDic: function rightDeleteDic(params) {
    return request.post('/app/v1/chk-point/del-point', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  rightaddNextPoint: function rightaddNextPoint(params) {
    return request.post('/app/v1/chk-point/add-point', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  moveTemp: function moveTemp(dicCode, id, moveFlag) {
    // return request.get(`/app/v1/dic/move-dicContent?id=${id}&moveFlag=${moveFlag}`)
    return request.get("/app/v1/dic/move-dicContent/".concat(dicCode, "?id=").concat(id, "&moveFlag=").concat(moveFlag)).then(function (res) {
      return res;
    });
  },
  // 用到了
  pasteTemplate: function pasteTemplate(params) {
    return request.post('/app/v1/chk-point/copy-point', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  designStandard: function designStandard(params) {
    return request.post('/app/v1/SpaceDesignStandard/queryByRoomID', params).then(function (res) {
      return res;
    });
  },
  // 用到了
  deleteImg: function deleteImg(fileId) {
    //效果图删除
    return request.post('/app/v1/file/delete/' + fileId).then(function (res) {
      return res;
    });
  },
  //审图要点导入 app
  importExcel: function importExcel(formdata, dicCode) {
    return beautyRequst.post('/app/v1/chk-point/import/excel?dicCode=' + dicCode, formdata, {
      responseType: 'blob',
      token: getValidToken()
    });
  },
  //审图要点下载模板 
  downImportExcel: function downImportExcel() {
    return beautyRequst.get('/app-doc-center/v1/chk-point/import/downloadTemplate', {
      responseType: 'blob',
      token: getValidToken()
    });
  }
};