var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "PlanApprovalDic" } }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "div",
        { staticClass: "leftTitle" },
        [
          _c("span", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("标准目录")
          ]),
          _c(
            "span",
            {
              staticStyle: {
                color: "#5c93cc",
                "font-size": "14px",
                cursor: "pointer"
              },
              on: { click: _vm.commit }
            },
            [
              _c("i", {
                staticClass: "el-icon-circle-plus-outline",
                staticStyle: { "margin-right": "5px", "font-size": "16px" }
              }),
              _vm._v("新增 ")
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "新增",
                visible: _vm.dialogVersionVisible,
                width: "800px",
                "custom-class": "config-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVersionVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c(
                  "div",
                  { staticClass: "addVersionName" },
                  [
                    _c("p", { staticClass: "title" }, [
                      _vm._v("输入字典组名称：")
                    ]),
                    _c("el-input", {
                      model: {
                        value: _vm.addVersionName,
                        callback: function($$v) {
                          _vm.addVersionName = $$v
                        },
                        expression: "addVersionName"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVersionVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addDicSure }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fileMenu" },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.templateArr,
              props: _vm.defaultProps,
              "highlight-current": "",
              "node-key": "id",
              "default-expanded-keys": _vm.unfoldIdArr
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c(
                    "div",
                    {
                      staticClass: "ellipsis",
                      staticStyle: { width: "90%", height: "100%" },
                      attrs: { title: data.folderName },
                      on: {
                        click: function($event) {
                          return _vm.clickNode(node, data)
                        }
                      }
                    },
                    [
                      _vm.isShowRechristen[node.id]
                        ? _c(
                            "div",
                            { staticClass: "rechristen" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: data.folderName,
                                  autofocus: true
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.rechristenCommitName,
                                  callback: function($$v) {
                                    _vm.rechristenCommitName = $$v
                                  },
                                  expression: "rechristenCommitName"
                                }
                              }),
                              _c("el-button", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-check",
                                  circle: "",
                                  size: "mini"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updateNameCommit(data)
                                  }
                                }
                              }),
                              _c("el-button", {
                                staticStyle: {
                                  "margin-left": "5px",
                                  "margin-right": "10px"
                                },
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-close",
                                  circle: "",
                                  size: "mini"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    ;(_vm.isShowRechristen = []),
                                      (_vm.rechristenCommitName = "")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("span", { attrs: { id: node.id } }, [
                                _vm._v(_vm._s(node.label))
                              ]),
                              _vm.Node.id === node.id
                                ? _c(
                                    "el-dropdown",
                                    {
                                      attrs: { placement: "bottom-start" },
                                      on: { command: _vm.handleCommand }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "el-dropdown-link",
                                          staticStyle: { cursor: "pointer" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-more"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticStyle: { margin: "0px" },
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "update" } },
                                            [_vm._v("修改")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "delete" } },
                                            [_vm._v("删除")]
                                          ),
                                          node.level < 4
                                            ? _c(
                                                "el-dropdown-item",
                                                { attrs: { command: "add" } },
                                                [_vm._v("添加")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "right" }, [
      _c(
        "div",
        { staticClass: "topButton" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.FieldOrder(1)
                }
              }
            },
            [_vm._v("上移")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.FieldOrder(2)
                }
              }
            },
            [_vm._v("下移")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.addTemplate("current")
                }
              }
            },
            [_vm._v("同级添加")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.addTemplate("down")
                }
              }
            },
            [_vm._v("降级添加")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.copyTemplate } },
            [_vm._v("复制模板")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.pasteTemplate } },
            [_vm._v("粘贴模板")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.exportBtn } },
            [_vm._v("导入")]
          ),
          _c("el-button", { on: { click: _vm.deleteTemp } }, [
            _vm._v("批量删除")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.templateTable,
                "row-key": "id",
                height: "650px",
                "highlight-current-row": "",
                border: "",
                "expand-row-keys": _vm.expands,
                "header-cell-style": _vm.getRowClass,
                "tree-props": {
                  children: "dictTree",
                  hasChildren: "hasChildren"
                }
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "current-change": _vm.handleCurrentChange,
                "expand-change": _vm.expandChange
              }
            },
            [
              _c("el-table-column", { attrs: { type: "selection" } }),
              _c("el-table-column", {
                attrs: { prop: "folderName", label: "要点名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.isShowTaleRechristen[$index]
                          ? _c(
                              "div",
                              {
                                staticClass: "rechristen",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: row.cnName,
                                    autofocus: true
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  },
                                  model: {
                                    value: _vm.tableRechristenCommitName,
                                    callback: function($$v) {
                                      _vm.tableRechristenCommitName = $$v
                                    },
                                    expression: "tableRechristenCommitName"
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.commitTableRen(row)
                                      }
                                    }
                                  },
                                  [_vm._v("提交")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.commitTableCancel(row)
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                )
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.cnName))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.tableUpdate(scope)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            scope.row.dictTree.length == "0"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.tableaddDic(scope)
                                      }
                                    }
                                  },
                                  [_vm._v("添加审图要点")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.tableDelete(scope)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加审图要点",
                visible: _vm.dialogDicVisible,
                width: "500px",
                "custom-class": "config-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogDicVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c(
                  "div",
                  { staticClass: "region-list" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          "label-width": "80px",
                          "label-position": "top"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "要点说明:" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 6 },
                              model: {
                                value: _vm.form.mainPoint,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "mainPoint", $$v)
                                },
                                expression: "form.mainPoint"
                              }
                            })
                          ],
                          1
                        ),
                        false
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "上传附件:" } },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: _vm.sendFile,
                                      "on-remove": _vm.handleRemove,
                                      "on-success": _vm.handleAvatarSuccess,
                                      "before-remove": _vm.beforeRemove,
                                      headers: _vm.headers,
                                      multiple: "",
                                      limit: 1,
                                      "on-exceed": _vm.handleExceed,
                                      "file-list": _vm.fileList
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        }
                                      },
                                      [_vm._v("点击上传")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogDicVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.tableaddDicSure }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-dialog",
            {
              staticClass: "uploadDialog",
              attrs: {
                title: "导入",
                visible: _vm.dialogVisible,
                width: "45%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "downTemplate" },
                [
                  _c("p", [_vm._v("请下载导入模板，按格式修改后导入")]),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.downTemplateClick }
                    },
                    [_vm._v("模板下载")]
                  )
                ],
                1
              ),
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    drag: "",
                    action: "#",
                    "on-change": _vm.uploadByJsqd,
                    "auto-upload": false,
                    "file-list": _vm.fileList,
                    multiple: ""
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")])
                  ])
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.uploadDiaBtn }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }