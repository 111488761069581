import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
export function promiseWrapper(promise) {
  var placeHolder = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (!promise instanceof Promise) return Promise.reject('参数应为Promise实例引用');
  return promise.then(function (res) {
    if ((res === null || res === void 0 ? void 0 : res.result) == '0' || res === true) return res;
    throw new Error('接口连通性正常 返回数据异常');
  }).catch(function (err) {
    console.error("[Error] PromiseWrapper\u8BF7\u6C42\u5F02\u5E38\n\u8BE6\u7EC6\u9519\u8BEF\u4FE1\u606F: ".concat(placeHolder).concat(err.message));
    return {};
  });
}